// store.ts
import { configureStore } from "@reduxjs/toolkit";
import { cartReducer, orderReducer, productReducer, categoriesReducer, supplierReducer, userReducer, allOrderReducer } from './slices';

export const store = configureStore({
  reducer: {
    products: productReducer,
    cart: cartReducer,
    orders: orderReducer,
    user: userReducer,
    categories: categoriesReducer,
    suppliers: supplierReducer,
    allOrders: allOrderReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
