import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getCategoriesAPI } from "../../services";

// Define the shape of a Category
export interface Category {
  id: string;
  name: string;
  description: string;
}

// Define the initial state
interface CategoriesState {
  items: Category[];
  loading: Boolean;
  error: string | null;
}

const initialState: CategoriesState = {
  items: [],
  loading: false,
  error: null,
};

// Async thunk to fetch categories from the backend
export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const response = await getCategoriesAPI();
    return response.data.categories; // Assuming the API returns an array of categories
  }
);

export const fetchCategoriesSilent = createAsyncThunk(
  "categories/fetchCategoriesSilent",
  async () => {
    const response = await getCategoriesAPI();
    return response.data.categories; // Assuming the API returns an array of categories
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.loading = false;
          state.items = action.payload;
        }
      )
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch categories";
      })
      .addCase(fetchCategoriesSilent.pending, (state) => {
        state.loading = false;
      })
      .addCase(
        fetchCategoriesSilent.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.loading = false;
          state.items = action.payload;
        }
      )
      .addCase(fetchCategoriesSilent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch categories";
      });
  },
});

export default categoriesSlice.reducer;
