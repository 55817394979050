//Small Image
import oakImage from "../../assets/images/rankTreeIcons/Icon_Oak.png";
import evergreenImage from "../../assets/images/rankTreeIcons/Icon_Evergreen.png";
import saplingImage from "../../assets/images/rankTreeIcons/Icon_Sapling.png";
import acornImage from "../../assets/images/rankTreeIcons/Icon_Acorn.png";
import seedlingImage from "../../assets/images/rankTreeIcons/Icon_Seedling.png";
//Icon Frames
import oakImageFrame from "../../assets/images/rankIconFrames/Icon_Oak_Frame.png";
import evergreenImageFrame from "../../assets/images/rankIconFrames/Icon_Evergreen_Frame.png";
import saplingImageFrame from "../../assets/images/rankIconFrames/Icon_Sapling_Frame.png";
import acornImageFrame from "../../assets/images/rankIconFrames/Icon_Acorn_Frame.png";
import seedlingImageFrame from "../../assets/images/rankIconFrames/Icon_Seedling_Frame.png";
import { getLevelName } from "./getLevelDetails";
import { SCORE_LEVEL } from "../../constants";

export const getLevelIcon = (score: number) => {
  const level = getLevelName(score);
  switch (level) {
    case SCORE_LEVEL.ACORN:
      return acornImage;
    case SCORE_LEVEL.SEEDLING:
      return seedlingImage;
    case SCORE_LEVEL.SAPLING:
      return saplingImage;
    case SCORE_LEVEL.OAK:
      return oakImage;
    case SCORE_LEVEL.EVERGREEN:
      return evergreenImage;
    default:
      return acornImage;
  }
};

export const getLargeLevelIconFrame = (score: number) => {
  const level = getLevelName(score);
  switch (level) {
    case SCORE_LEVEL.ACORN:
      return acornImageFrame;
    case SCORE_LEVEL.SEEDLING:
      return seedlingImageFrame;
    case SCORE_LEVEL.SAPLING:
      return saplingImageFrame;
    case SCORE_LEVEL.OAK:
      return oakImageFrame;
    case SCORE_LEVEL.EVERGREEN:
      return evergreenImageFrame;
    default:
      return acornImageFrame;
  }
};
