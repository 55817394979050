import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCartItemsForUserAPI,
  deleteCartForUserAPI,
  deleteCartItemForUserAPI,
  getCartItemsForUserAPI,
} from "../../services";

interface ICartItem {
  cartId: string;
  isLoading: boolean;
  productDetails: {
    productId: string;
    quantity: number;
    isOutOfStock: boolean;
  }[];
}

const initialState: ICartItem = {
  cartId: "",
  productDetails: [],
  isLoading: false,
};

interface ICreateCartItemArgs {
  productId: number;
  quantity: number;
}

interface ICartItemDeleteArgs {
  productId: string;
}

export const getCartItemsForUser = createAsyncThunk(
  "cart/getUsersItems",
  async () => {
    const result = await getCartItemsForUserAPI();
    if (result.success) {
      return result;
    }
  }
);
export const getCartItemsForUserSilent = createAsyncThunk(
  "cart/getUsersItemsSilent",
  async () => {
    const result = await getCartItemsForUserAPI();
    if (result.success) {
      return result;
    }
  }
);

export const deleteCartForUser = createAsyncThunk(
  "cart/deleteCartForUser",
  async () => {
    const result = await deleteCartForUserAPI();
    if (result.success) {
      return result;
    }
  }
);

export const deleteCartItemForUser = createAsyncThunk(
  "cart/deleteCartItemForUser",
  async ({ productId }: ICartItemDeleteArgs) => {
    const result = await deleteCartItemForUserAPI(productId);
    if (result.success) {
      return result;
    }
  }
);

export const createCartItemsForUser = createAsyncThunk(
  "cart/createCartItem",
  async ({ productId, quantity }: ICreateCartItemArgs, { rejectWithValue }) => {
    try {
      const result = await createCartItemsForUserAPI(productId, quantity);
      if (result.success) {
        return result;
      } else {
        return rejectWithValue(result.error || "Failed to create cart item.");
      }
    } catch (error: any) {
      return rejectWithValue(error.message || "An unexpected error occurred.");
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCartItemsForUser.fulfilled, (state, action) => {
        if (action.payload.data.length === 0) {
          return (state = { cartId: "", productDetails: [], isLoading: false });
        } else {
          return (state = { ...action.payload.data[0], isLoading: false });
        }
      })
      .addCase(getCartItemsForUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCartItemsForUser.rejected, (state, action) => {
        return (state = {
          cartId: "",
          productDetails: [],
          isLoading: false,
        });
      })
      .addCase(getCartItemsForUserSilent.fulfilled, (state, action) => {
        if (action.payload.data.length === 0) {
          return (state = { cartId: "", productDetails: [], isLoading: false });
        } else {
          return (state = { ...action.payload.data[0], isLoading: false });
        }
      })
      .addCase(getCartItemsForUserSilent.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCartItemsForUserSilent.rejected, (state, action) => {
        return (state = {
          cartId: "",
          productDetails: [],
          isLoading: false,
        });
      })
      .addCase(createCartItemsForUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createCartItemsForUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCartItemsForUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCartForUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCartForUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCartForUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCartItemForUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCartItemForUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCartItemForUser.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

// export const { add, remove, updateQuantity, clearCart, removeOutOfStockItems } =
//   cartSlice.actions;
export default cartSlice.reducer;
