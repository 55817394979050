import { API } from "../utils";

export const getOrdersByIdAPI = async () => {
  const response = await API.get(`GetOrdersById`);
  return response.data;
};

export const getAllOrdersAPI = async () => {
  const response = await API.get(`GetAllOrders`);
  return response.data;
};

export const createOrderAPI = async () => {
  const response = await API.post(`CreateOrder`);
  return response.data;
};

export const updateOrderStatusAPI = async (orderId: string, newStatus: string) => {
  try {
    const response = await API.put(`UpdateOrderStatus`, { orderId, newStatus });
    return response.data;
  } catch (error) {
    throw error;
  }
};