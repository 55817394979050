import NiceModal from "@ebay/nice-modal-react";
import ErrorPopUp from "./ErrorPopUp";

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const checkNumberInRangeInclusive = (
  num: number,
  lowRange: number,
  highRange: number
) => {
  return num >= lowRange && num <= highRange;
};

export const showReload = (message: string) => {
  if (message) {
    NiceModal.show(ErrorPopUp, { isOpen: true, subTitle: message });
  }
};
