import axios, { AxiosError } from "axios";
import { acquireFunctionAppToken, acquireGraphToken } from "../auth/getAccessToken";
import { msalInstance } from "../auth/authConfig";
import { showReload } from "../functions";

// Type for API Error Response
interface ApiErrorResponse {
  error: {
    message: string;
    status?: number;
  };
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: (params) => {
    return Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  },
});

// Helper to determine if URL is for Graph API
const isGraphApiUrl = (url: string): boolean => {
  const graphEndpoint = process.env.REACT_APP_GRAPH_ENDPOINT;
  return url.startsWith(graphEndpoint || '');
};

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // Get appropriate token based on the URL
      const token = isGraphApiUrl(config.url || '')
        ? await acquireGraphToken(msalInstance)
        : await acquireFunctionAppToken(msalInstance);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // Only add code and clientId for Function API calls
      if (!isGraphApiUrl(config.url || '')) {
        config.params = {
          ...config.params,
          code: process.env.REACT_APP_API_CODE,
          clientId: process.env.REACT_APP_API_CLIENT_ID,
        };
      }

      return config;
    } catch (error) {
      console.error('Error in request interceptor:', error);
      return Promise.reject(error);
    }
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiErrorResponse>) => {
    // Skip handling for Graph API errors
    if (isGraphApiUrl(error.config?.url || '')) {
      return Promise.reject(error);
    }

    let errorMessage = "Something went wrong. Please refresh the page.";
    
    if (error.response) {
      const status = error.response.status;
      
      switch (status) {
        case 401:
          errorMessage = "Session expired. Please sign in again.";
          // You might want to trigger a sign-in here
          break;
        case 403:
          errorMessage = "You don't have permission to perform this action.";
          break;
        case 408:
          errorMessage = "Request timed out. Please refresh the page.";
          showReload(errorMessage);
          break;
        case 429:
          errorMessage = "Too many requests. Please try again later.";
          break;
        case 500:
          errorMessage = "Server error. Please try again later.";
          break;
        default:
          if (error.response.data?.error?.message === "Connection is closed.") {
            showReload(errorMessage);
          }
      }
    } else if (error.request) {
      // Request was made but no response received
      errorMessage = "No response from server. Please check your connection.";
      showReload(errorMessage);
    }

    // Log error for debugging
    console.error('API Error:', {
      status: error.response?.status,
      message: errorMessage,
      originalError: error.response?.data?.error
    });

    return Promise.reject({
      ...error,
      userMessage: errorMessage
    });
  }
);

// Add type for the error response in your components
export interface AxiosCustomError extends AxiosError {
  userMessage?: string;
}

export default axiosInstance;
