import { IMainMenuItem, MAIN_MENU } from "../../constants";

export const getAccessibleMenuItems = (
  accessItems: Array<Pick<IMainMenuItem, "id" | "name">>
): Array<IMainMenuItem> => {
  try {
    return [
      // Add the root leaderboard
      // MAIN_MENU[0],
      ...MAIN_MENU.filter(({ name }) =>
        accessItems.some((accessItem) => name === accessItem.name)
      ),
    ];
  } catch (error) {
    return [];
  }
};
