import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        pricetag: React.CSSProperties;
    }
  
    // allow configuration using `createTheme()`
    interface TypographyVariantsOptions {
        pricetag?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        pricetag: true;
    }
}



const customTheme = createTheme({
    typography: {
      fontFamily: 'Poppins, Arial, sans-serif',
      h6: {
        fontSize: '0.875rem', // Change the font size here
        fontWeight: 500,    // Optional: customize font weight
        color: '#373737',
        lineHeight: 1.1,
      },
      pricetag: {
        fontSize: "1.2rem",
        fontWeight: 600,
        color: "#000",
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins, Arial, sans-serif',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins, Arial, sans-serif',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0, // Extra small
        sm: 750, // Small
        md: 930, // Medium
        lg: 1300, // Large
        xl: 1750, // Extra large
      },
    },
  });

export default customTheme;