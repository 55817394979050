import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserAPI, getUserFeaturesAPI } from "../../services";
export interface IMainMenuItem {
  id: number;
  name: string;
  displayName: string;
  path: string;
  isAdminDropDown: boolean;
}

export interface IEmployee {
  designation: string;
  generalCoins: number;
  generalPoints: number;
  parCoins: number;
  points: number;
  rank: number;
  parPoints: number;
  tier: number;
  totalCoins: number;
  userEmail: string;
  userId: number;
  userName: string;
  role: number;
}

export interface IUser {
  currentUserDetails: IEmployee;
  isFeaturesLoading: boolean;
  mainMenuItems: Array<Pick<IMainMenuItem, "id" | "name">>;
}

const initialState: IUser = {
  currentUserDetails: {
    userId: 0,
    userName: "",
    userEmail: "",
    points: 0,
    rank: 0,
    parPoints: 0,
    generalPoints: 0,
    totalCoins: 0,
    parCoins: 0,
    generalCoins: 0,
    tier: 0,
    designation: "",
    role: 3,
  },
  isFeaturesLoading: false,
  mainMenuItems: [],
};

export const getUser = createAsyncThunk("users/getUsers", async () => {
  const response = await getUserAPI();
  if (response.success) {
    return response;
  }
});

export const getUserSilent = createAsyncThunk(
  "users/getUsersSilent",
  async () => {
    const response = await getUserAPI();
    if (response.success) {
      return response;
    }
  }
);

export const getUserFeatures = createAsyncThunk(
  "users/getUserFeatures",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserFeaturesAPI();
      if (response.success) {
        return response;
      }
      return rejectWithValue("Failed to fetch user features");
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch user features");
    }
  }
);

export const getUserFeaturesSilent = createAsyncThunk(
  "users/getUserFeaturesSilent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserFeaturesAPI();
      if (response.success) {
        return response;
      }
      return rejectWithValue("Failed to fetch user features");
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch user features");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle getUsers cases
      .addCase(getUser.pending, (state) => {
        // Optional: Add loading state if needed
        state.isFeaturesLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isFeaturesLoading = false;
        state.currentUserDetails = action.payload.data;
      })
      .addCase(getUser.rejected, (state) => {
        state.isFeaturesLoading = false;
      })
      // Handle getUserFeatures cases
      .addCase(getUserFeatures.pending, (state) => {
        state.isFeaturesLoading = true;
      })
      .addCase(getUserFeatures.fulfilled, (state, action) => {
        state.mainMenuItems = action.payload.data;
        state.isFeaturesLoading = false;
      })
      .addCase(getUserFeatures.rejected, (state) => {
        state.mainMenuItems = [];
        state.isFeaturesLoading = false;
      })
      .addCase(getUserSilent.pending, (state) => {
        state.isFeaturesLoading = false;
      })
      .addCase(getUserSilent.fulfilled, (state, action) => {
        state.isFeaturesLoading = false;
        state.currentUserDetails = action.payload.data;
      })
      .addCase(getUserSilent.rejected, (state) => {
        state.isFeaturesLoading = false;
      })
      .addCase(getUserFeaturesSilent.pending, (state) => {
        state.isFeaturesLoading = false;
      })
      .addCase(getUserFeaturesSilent.fulfilled, (state, action) => {
        state.mainMenuItems = action.payload.data;
        state.isFeaturesLoading = false;
      })
      .addCase(getUserFeaturesSilent.rejected, (state) => {
        state.mainMenuItems = [];
        state.isFeaturesLoading = false;
      });
  },
});

export default userSlice.reducer;
