export interface IMainMenuItem {
  id: number;
  name: string;
  displayName: string;
  path: string;
  isAdminDropDown: boolean;
}

export const enum PAGE_TITLE {
  ADD_PRODUCT_TITLE = "ADD PRODUCT",
  PRODUCT_CATELOG_TITLE = "PRODUCT CATELOG",
  EDIT_PRODUCT_TITLE = "EDIT PRODUCT",
  ORDER_LIST_TITLE = "ORDERS",
}

export enum SCORE_LEVEL {
  ACORN = "Acorn",
  SEEDLING = "Seedling",
  SAPLING = "Sapling",
  OAK = "Oak",
  EVERGREEN = "Evergreen",
}

export const SCORE_LEVEL_LIMIT = {
  acorn: {
    low: 0,
    high: 100,
  },
  seedling: {
    low: 101,
    high: 250,
  },
  sapling: {
    low: 251,
    high: 500,
  },
  oak: {
    low: 501,
    high: 999,
  },
  evergreen: {
    low: 1000,
    high: +Infinity,
  },
};

export const enum MENU_ITEM_NAME {
  PRODUCT_CATALOG = "product_catalog",
  ORDERS = "orders",
  ADD_PRODUCTS = "add_products",
  EDIT_PRODUCTS = "edit_products",
}

export const enum MENU_ITEM_DISPLAY_NAME {
  PRODUCT_CATALOG = "Product Catalog",
  ORDERS = "Orders",
  ADD_PRODUCTS = "Add Products",
  EDIT_PRODUCTS = "Edit Products",
}

export const MAIN_MENU: Array<IMainMenuItem> = [
  {
    id: 1,
    name: MENU_ITEM_NAME.PRODUCT_CATALOG,
    displayName: MENU_ITEM_DISPLAY_NAME.PRODUCT_CATALOG,
    path: "/product-catalog",
    isAdminDropDown: true,
  },
  {
    id: 2,
    name: MENU_ITEM_NAME.ORDERS,
    displayName: MENU_ITEM_DISPLAY_NAME.ORDERS,
    path: "/order-list",
    isAdminDropDown: true,
  },
  {
    id: 3,
    name: MENU_ITEM_NAME.ADD_PRODUCTS,
    displayName: MENU_ITEM_DISPLAY_NAME.ADD_PRODUCTS,
    path: "/add-products",
    isAdminDropDown: false,
  },
  {
    id: 4,
    name: MENU_ITEM_NAME.EDIT_PRODUCTS,
    displayName: MENU_ITEM_DISPLAY_NAME.EDIT_PRODUCTS,
    path: "/edit-product/:id",
    isAdminDropDown: false,
  },
];
