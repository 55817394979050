import { API } from "../utils";

export const getProductsAPI = async () => {
  const response = await API.get("GetProducts");
  return response.data;
};

export const createProductAPI = async (productData: any) => {
    const response = await API.post("CreateProduct", productData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
};

export const editProductAPI = async (productData: any, productId: string) => {
  const response = await API.put(`EditProductById?productId=${productId}`, productData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};