import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

export interface LoadingProps {
  minHeight?: string;
  height?: string;
  isLoading: boolean;
  initailLoad?: boolean;
}

const Loading = ({
  minHeight = "95%",
  height = "60vh",
  isLoading = false,
  initailLoad = false,
}: LoadingProps) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        backgroundColor: initailLoad ? "rgba(0, 0, 0, 0.95)" : "",
        zIndex: (theme: any) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
export default Loading;
