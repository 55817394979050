import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSupplierAPI } from "../../services";

// Define the shape of a Category
interface Supplier {
  id: number;
  name: string;
  contactPerson: string;
  email: string;
  phone: number;
  address: string;
}

// Define the initial state
interface SupplierState {
  items: Supplier[];
  loading: Boolean;
  error: string | null;
}

const initialState: SupplierState = {
  items: [],
  loading: false,
  error: null,
};

// Async thunk to fetch categories from the backend
export const fetchSupplier = createAsyncThunk(
  "suppliers/fetchSupplier",
  async () => {
    const response = await getSupplierAPI();
    return response.data.suppliers; // Assuming the API returns an array of categories
  }
);

const supplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplier.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch suppliers";
      });
  },
});

export default supplierSlice.reducer;
