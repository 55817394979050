import { API } from "../utils";

export const getUserAPI = async () => {
  const response = await API.get("users");
  return response.data;
};

export const getUserFeaturesAPI = async () => {
  const response = await API.get(`GetUserFeatures`);
  return response.data;
};

export const reduceCoinsForUserAPI = async () => {
  const response = await API.post("");
  return response.data;
};
