import { SCORE_LEVEL, SCORE_LEVEL_LIMIT } from "../../constants";
import { checkNumberInRangeInclusive } from "./functions";

export const getLevelName = (score: number): SCORE_LEVEL | undefined => {
  const { acorn, seedling, sapling, oak, evergreen } = SCORE_LEVEL_LIMIT;
  if (checkNumberInRangeInclusive(score, acorn.low, acorn.high)) {
    return SCORE_LEVEL.ACORN;
  }
  if (checkNumberInRangeInclusive(score, seedling.low, seedling.high)) {
    return SCORE_LEVEL.SEEDLING;
  }
  if (checkNumberInRangeInclusive(score, sapling.low, sapling.high)) {
    return SCORE_LEVEL.SAPLING;
  }
  if (checkNumberInRangeInclusive(score, oak.low, oak.high)) {
    return SCORE_LEVEL.OAK;
  }
  if (checkNumberInRangeInclusive(score, evergreen.low, evergreen.high)) {
    return SCORE_LEVEL.EVERGREEN;
  }
};

export const getLevelRange = (score: number) => {
  const level = getLevelName(score);
  const { acorn, seedling, sapling, oak, evergreen } = SCORE_LEVEL_LIMIT;

  switch (level) {
    case SCORE_LEVEL.ACORN:
      return acorn.low + " - " + acorn.high;
    case SCORE_LEVEL.SEEDLING:
      return seedling.low + " - " + seedling.high;
    case SCORE_LEVEL.SAPLING:
      return sapling.low + " - " + sapling.high;
    case SCORE_LEVEL.OAK:
      return oak.low + " - " + oak.high;
    case SCORE_LEVEL.EVERGREEN:
      return evergreen.low + "+";
    default:
      return acorn.low + " - " + acorn.high;
  }
};
