import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Toaster } from "react-hot-toast";

import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./utils/auth/authConfig";
import NiceModal from "@ebay/nice-modal-react";
import { EventType, AuthenticationResult } from "@azure/msal-browser";

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
  ) {
    const result = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(result.account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <NiceModal.Provider>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
        <Toaster />
      </Provider>
    </MsalProvider>
  </NiceModal.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
