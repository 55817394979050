import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createOrderAPI, getOrdersByIdAPI } from "../../services";

interface IOrderProduct {
  productId: string;
  quantity: string;
  pricePerUnit: string;
  totalPrice: string;
}
export interface IOrder {
  orderId: string;
  products: IOrderProduct[];
  orderDate: string; // Store date as an ISO string
  orderStatus?: string;
  orderPrice: number;
  billingAddress: string;
  shippingAddress: string;
}

const initialState: {
  isLoading: boolean;
  orders: IOrder[];
} = {
  isLoading: false,
  orders: [],
};

export const getOrdersById = createAsyncThunk(
  "orders/getOrderById",
  async () => {
    try {
      const response = await getOrdersByIdAPI();
      if (response.success) {
        return response;
      }
    } catch (e: any) {
      console.log("Error in getOrdersById ", e);
    }
  }
);

export const getOrdersByIdSilent = createAsyncThunk(
  "orders/getOrderByIdSilent",
  async () => {
    try {
      const response = await getOrdersByIdAPI();
      if (response.success) {
        return response;
      }
    } catch (e: any) {
      console.log("Error in getOrdersById ", e);
    }
  }
);

export const createOrder = createAsyncThunk("order/create", async () => {
  try {
    const response = await createOrderAPI();
    if (response.success) {
      console.log(response);
      return response;
    }
  } catch (e: any) {
    console.log("Error in getAllOrders ", e);
  }
});

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOrdersById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action?.payload?.data?.orders) {
          state.orders = action.payload?.data?.orders;
        } else {
          state.orders = [];
        }
      })
      .addCase(getOrdersById.rejected, (state) => {
        state.isLoading = false;
        state.orders = [];
      })
      .addCase(getOrdersById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrdersByIdSilent.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action?.payload?.data) {
          state.orders = action.payload?.data?.orders;
        } else {
          state.orders = [];
        }
      })
      .addCase(getOrdersByIdSilent.rejected, (state) => {
        state.isLoading = false;
        state.orders = [];
      })
      .addCase(getOrdersByIdSilent.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(createOrder.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrder.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default orderSlice.reducer;
