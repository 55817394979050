import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getProductsAPI,
  editProductAPI,
  createProductAPI,
} from "../../services";

// Updated IProduct interface to match the API response
export interface IProduct {
  id: string;
  productName: string;
  description: string;
  category: string;
  categoryName: string;
  supplier: string;
  price: number;
  currentStock: number;
  images: {
    productImageId: number;
    blobUrl: string;
    isPrimary: boolean;
    crtOn: string;
  }[];
  crtOn?: string;
  lastModOn?: string;
}

interface ProductsState {
  products: IProduct[];
  loading: boolean;
  error: string | null;
}

const initialState: ProductsState = {
  products: [],
  loading: false,
  error: null,
};

// Fetch products with mapped response data
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const response = await getProductsAPI();
    return response;
  }
);

export const fetchProductsSilent = createAsyncThunk(
  "products/fetchProductsSilent",
  async () => {
    const response = await getProductsAPI();
    return response;
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (productData: any, { rejectWithValue }) => {
    try {
      // Log the data being sent
      console.log("Sending product data:", productData);

      const response = await createProductAPI(productData);
      return response;
    } catch (error: any) {
      // Use rejectWithValue to properly handle the error
      return rejectWithValue(error.message || "Failed to create product");
    }
  }
);

export const editProduct = createAsyncThunk(
  "products/editProduct",
  async ({
    productData,
    productId,
  }: {
    productData: Partial<IProduct>;
    productId: string;
  }) => {
    const response = await editProductAPI(productData, productId);
    return response;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<IProduct>) => {
      state.products.push(action.payload);
    },
    remove: (state, action: PayloadAction<string>) => {
      state.products = state.products.filter(
        (item) => item.id !== action.payload
      );
    },
    reduceCurrentStock: (
      state,
      action: PayloadAction<{ productId: string; quantity: number }>
    ) => {
      const { productId, quantity } = action.payload;
      const product = state.products.find((item) => item.id === productId);
      if (product) {
        product.currentStock = Math.max(0, product.currentStock - quantity);
      }
    },
    increaseCurrentStock: (
      state,
      action: PayloadAction<{ productId: string; quantity: number }>
    ) => {
      const { productId, quantity } = action.payload;
      const product = state.products.find((item) => item.id === productId);
      if (product) {
        product.currentStock += quantity;
      }
    },
    updateProduct: (state, action: PayloadAction<IProduct>) => {
      const updatedProduct = action.payload;
      const index = state.products.findIndex(
        (item) => item.id === updatedProduct.id
      );
      if (index !== -1) {
        state.products[index] = updatedProduct;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        const newList = action.payload.data.products.map((product: any) => ({
          id: product.id,
          productName: product.name,
          description: product.description,
          category: product.catId,
          categoryName: product.categoryName,
          supplier: product.supId,
          price: product.priceInCoins,
          currentStock: product.stock,
          images: product.images?.map((image: any) => ({
            productImageId: image.productImageId,
            blobUrl: image.blobUrl,
            isPrimary: image.isPrimary,
            crtOn: image.crtOn,
          })),
          crtOn: product.crtOn,
          lastModOn: product.lastModOn,
        }));
        state.products = newList;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch products";
      })
      .addCase(fetchProductsSilent.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchProductsSilent.fulfilled, (state, action) => {
        state.loading = false;
        const newList = action.payload.data.products.map((product: any) => ({
          id: product.id,
          productName: product.name,
          description: product.description,
          category: product.catId,
          categoryName: product.categoryName,
          supplier: product.supId,
          price: product.priceInCoins,
          currentStock: product.stock,
          images: product.images?.map((image: any) => ({
            productImageId: image.productImageId,
            blobUrl: image.blobUrl,
            isPrimary: image.isPrimary,
            crtOn: image.crtOn,
          })),
          crtOn: product.crtOn,
          lastModOn: product.lastModOn,
        }));
        state.products = newList;
      })
      .addCase(fetchProductsSilent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch products";
      })
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products.push(action.payload);
        state.error = null;
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to create product";
        // Log the error in the reducer
        console.error("Product creation failed:", action.payload);
      })
      .addCase(editProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.loading = false;
        const updatedProduct = action.payload;
        const index = state.products.findIndex(
          (item) => item.id === updatedProduct.id
        );
        if (index !== -1) {
          state.products[index] = updatedProduct;
        }
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update product";
      });
  },
});

export const {
  add,
  remove,
  reduceCurrentStock,
  increaseCurrentStock,
  updateProduct,
} = productSlice.actions;

export default productSlice.reducer;
