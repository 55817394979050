import { API } from "../utils";

export const getCartItemsForUserAPI = async () => {
  const response = await API.get(`GetCartByUserId`);
  return response.data;
};

export const createCartItemsForUserAPI = async (
  productId: number,
  quantity: number
) => {
  const response = await API.post(`AddToCartByUserId`, {
    params: {
      productId,
      quantity,
    },
  });
  return response.data;
};

export const deleteCartForUserAPI = async () => {
  const response = await API.delete(`DeleteCart`);
  return response.data;
};

export const deleteCartItemForUserAPI = async (productId: string) => {
  const response = await API.delete(`DeleteCartItem?productId=${productId}`);
  return response.data;
};
