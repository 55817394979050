import { PublicClientApplication, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

type TokenRequest = {
  scopes: string[];
  account: AccountInfo;
};

type ApiScopes = {
  FUNCTION_APP: string[];
  GRAPH_API: string[];
};

const API_SCOPES: ApiScopes = {
  FUNCTION_APP: [`api://${process.env.REACT_APP_API_CLIENT_ID}/access_as_user`],
  GRAPH_API: ["User.Read", "People.Read", "User.ReadBasic.All"]
};

const acquireToken = async (
  msalInstance: PublicClientApplication, 
  scopes: string[]
): Promise<string> => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error("No active account! Please sign in before proceeding.");
  }

  const request: TokenRequest = {
    scopes,
    account: activeAccount || accounts[0]
  };

  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(request);
    return accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.warn("Silent token acquisition failed, acquiring token using redirect");
      await msalInstance.acquireTokenRedirect(request);
      return ""; 
    }
    throw error;
  }
};

export const acquireFunctionAppToken = async (
  msalInstance: PublicClientApplication
): Promise<string> => {
  return acquireToken(msalInstance, API_SCOPES.FUNCTION_APP);
};

export const acquireGraphToken = async (
  msalInstance: PublicClientApplication
): Promise<string> => {
  return acquireToken(msalInstance, API_SCOPES.GRAPH_API);
};